import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import PostWrapper from '../components/PostWrapper/PostWrapper';
import WidgetWrapper from '../components/WidgetWrapper/WidgetWrapper';
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexInfoQuery {
      blog {
        pages {
          title
          image {
            handle
          }
          content {
            html
          }
        }
        blogInfoes {
          lastPost
          postsOnIndex
        }
      }
    }
  `) 
  return (
    <Layout>
      <SEO title="Home" />
      <WidgetWrapper 
        widgetContent={ data.blog.pages[0] ? data.blog.pages[0] : '' }
        displayStyle={ data.blog.pages[0] ? 'block' : 'none' } 
      />
      <PostWrapper 
        blogHeader={data.blog.blogInfoes[0].lastPost}
        postCount={Number('-' + data.blog.blogInfoes[0].postsOnIndex)}
      />
      <WidgetWrapper 
        widgetContent={ data.blog.pages[1] ? data.blog.pages[1] : '' }
        displayStyle={ data.blog.pages[1] ? 'block' : 'none' } 
      />
    </Layout>
  )
}

export default IndexPage;