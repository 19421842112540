import React from 'react';
import './WidgetWrapper.css'

const WidgetWrapper = ({ widgetContent, displayStyle } ) => (
    <div className='widgetWrapper' style={{display:`${displayStyle}`}}>
      
      <div className='widgetWrapper__image'
        style={{ background:widgetContent.image ? (`url(https://media.graphcms.com/${widgetContent.image.handle}) center/cover no-repeat`) : 'transparent'}}
      ></div>
      <div className='widgetWrapper__contentWrapper'>
        <h1 className='contentWrapper__title'>{ widgetContent !== '' ? widgetContent.title : '' }</h1>
        <div className='contentWrapper__content'>
            <span dangerouslySetInnerHTML={{ __html: widgetContent !== '' ? widgetContent.content.html : '' }} />
        </div>
      </div>
    </div>
);

export default WidgetWrapper;